.cursor {
    position: fixed;
    z-index: 1000001;
    width: 100px;
    height: 100px;
    margin-top: -10px;
    margin-left: 10px;
    font-size: 15px;
    color: #CCFF00;
    opacity: 1;
    transition-duration: 0.5s;
    transition-property: opacity;
    pointer-events: none;
   
    @media (max-width: 900px) {
        display: none;
    }
}