@use './_colors.scss';

body {
  width: 100%;
  height: 100%;
  font-family: Helvetica, sans-serif;
  overflow: hidden;

  * {
    font-family: 'HN';
  }
}

@font-face {
  font-family: 'Maelstrom';
  src: url('../public/TestMaelstromSans-Bold.otf');
}

@font-face {
  font-family: 'HN';
  src: url('../public/HelveticaNeue-Light.ttf');
}

.App {
  width: 100%;
  height: 100%;
  display: block;
  box-sizing: border-box;
  top: 0;
  position: absolute;
  background: rgb(22, 27, 30);
  background: linear-gradient(180deg, rgba(22, 27, 30, 0.85) 0%, rgba(16, 19, 20, 1) 100%);

  // @media (max-width: 600px) {
  //   background-color: transparent;
  //   background: transparent;
  // }
}

* {
  font-weight: normal;
}

header {
  position: absolute;
  top: 0px;
  left: 0px;
  grid-template-columns: repeat(12, 1fr);
  display: grid;
  width: 100vw;
  padding: 20px;
  padding-bottom: 0;
  grid-gap: 20px;
  height: 17.3vw;
  opacity: 0;

  * {
    color: #CCFF00;
  }

  h1 {
    font-family: 'Maelstrom';
    line-height: 13.2vw;
    margin-top: 20px;
    font-size: 22.5vw;
    grid-column: 1/7;
  }

  .header__text {
    grid-column: 8/13;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    li {
      display: flex;
      justify-content: flex-start;
      margin-top: -4px;
      .number {
        display: none;
      }

      &:hover {
        .number {
          display: inline-block;
        }
        .letter {
          display: none;
        }
      }
    }

    p {
      font-size: 33px;
      line-height: 40px;
    }

    .list__num {
      font-size: 15px;
      margin-right: 10px;
      line-height: 25px;
    }

    .marquee3k {
      border-top: solid 1px #CCFF00;
      border-bottom: solid 1px #CCFF00;
      height: 50px;
      overflow: hidden;
      transition-duration: .3s;

      &:hover {
        border-color: white;

        p {
          color: white;
        }
      }

      p {
        margin-top: 6px;
        margin-right: 10px;
        transition-duration: .3s;
        font-size: 15px;
        text-transform: uppercase;
      }
    }
  }

  @media (max-width: 900px) {
    padding: 10px;
    grid-gap: 10px;
    h1 {
      line-height: 25vw;
      margin-top: 20px;
      font-size: 44vw;
      grid-column: 1/12;
      margin-bottom: 7vw;
    }

    .header__text {
      grid-column: 5/13;

      p {
        font-size: 21px;
        line-height: 30px;
      }
      li {
        &:hover {
            .number {
              display: none;
            }
            .letter {
              display: inline-block;
            }
          }
        }

      .list__num {
        font-size: 10px;
        margin-right: 10px;
        line-height: 20px;
      }

      .marquee3k {
        height: 30px;
        width: calc(100vw - 20px);
        left: 10px;
        position: fixed;
        bottom: 20px;

        &:hover {
          border-color: white;

          p {
            color: white;
          }
        }

        p {
          margin-top: 1px;
          margin-right: 10px;
          font-size: 15px;
        }
      }
    }
  }

}

.about {
  font-size: 33px;
  line-height: 110%;
  position: absolute;
  left: 20px;
  bottom: 15px;
  color: #CCFF00;
  opacity: 0;
  // width: calc(50vw - 40px);
  @media (max-width: 900px) {
    font-size: 25px;
    bottom: 65px;
    left: 10px;
    line-height: 110%;
  }
  @media (max-width: 600px) {
    font-size: 4.5vw;
  }
}


.three-container {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  pointer-events: none !important;
  // color: white !important;
  color: #CCFF00;
  opacity: 0;
  height: 100vh;
  transition-duration: 1s;
  transition-property: opacity;
  transition-delay: 2s;

  &#three-container--glass {
    opacity: 1;
    height: 100vh !important;
    // animation: appear 2s forwards 9.5s;
  }

  @media (max-width: 900px) {
    // animation: paddingTop 7s forwards ease-out;
    margin-top: 0vh;

    @keyframes paddingTop {
      100% {
        margin-top: 10vh;
      }
    }
  }
  @media (max-width: 500px) {
    animation: paddingTop2 7s forwards ease-out;
    margin-top: 0vh;

    @keyframes paddingTop2 {
      100% {
        margin-top: 3vh;
      }
    }
  }

  @keyframes appeardisappear {
    0% {
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    80% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }

  @keyframes appear {
    100% {
      opacity: 1;
    }
  }
}


p {
  margin: 0;
  line-height: 130%;
  font-size: 15px;
}

footer {
  position: absolute;
  right: 20px;
  top: 0;
  width: 30px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #CCFF00;
  opacity: 0;

  a {
    width: 30px;
    height: 30px;
    display: block;
    margin: 8px 0;
    filter: grayscale(1);
    transition-duration: .3s;

    &:hover {
      filter: grayscale(0);
    }
  }
  @media (max-width: 900px) {
    height: 100%;
    right: 10px;
    a {
      &:hover {
            filter: grayscale(1);
          }
      }
  }
}


.header__text,
.about {
  cursor: default;
}

.contact {
  * {
    cursor: pointer !important;
  }
}

.loading {
  font-size: 15px;
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  justify-content: center;
  align-items: center;
  transition-duration: .5s;
  transition-property: opacity;

  p {
    width: 149px;
    span {
      transition-duration: .5s;
    }
  }
}

.app--loaded {
  .about, footer, .header {
    animation: appear 1s forwards 8s;
    z-index: 2;
  }

  @media (max-width: 600px) {
    .about, footer, .header {
      animation: appear 1s forwards 5s;
    }
  }
}

.bg-vid {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100vw;
  height:  100vh;
  object-fit: cover;
  transform: scale(1);
  transform-origin: center;
  opacity: 0;
  transition-duration: 3s;
  transition-delay: 2s;
  // transition-property: opacity, transform;

  &.bg-vid--loaded {
    opacity: 1;
    transform: scale(1.2);
  }
}